<template>
  <img class="background-image" src="./assets/bg.png" alt="background" />
</template>

<script>
export default {
  methods: {
    isWeChat() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("isWeChat", true);
        return true;
      } else {
        console.log("isWeChat", false);

        if (window.location.hostname.includes("aiwan.io")) {
          let kefuUrls = [
            "https://aiwan1.io",
            "https://aiwan2.io",
            "https://aiwan3.io",
          ];
          const checkUrl = async (url) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.onload = () => resolve(true);
              img.onerror = () => resolve(false);
              img.src = `${url}/favicon.ico?t=${Date.now()}`;
            });
          };

          (async () => {
            let availableUrl = "";
            for (const url of kefuUrls) {
              if (await checkUrl(url)) {
                availableUrl = url;
                break;
              }
            }
            if (availableUrl) {
              window.location.href = availableUrl;
            } else {
              window.location.href = kefuUrls[kefuUrls.length - 1];
            }
          })();
        } else {
          window.location.href = "/download";
        }

        return false;
      }
    },
  },
  mounted() {
    this.isWeChat();
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
.background-image {
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  display: block;
  position: absolute;
  z-index: -1;
}
</style>
